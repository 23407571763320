<template>
    <section class="section">
        <h2 class="mb-3">ユーザ管理</h2>

        <inline-loader v-if="loading"></inline-loader>
        <template v-else>
            <div v-for="user of users" :key="user.user_id" class="row mb-2">
                <div class="col-4">
                    <input type="text" class="form-control" v-model="user.user_name" placeholder="名前" required>
                </div>
                <div class="col-8">
                    <input type="email" class="form-control" v-model="user.email" placeholder="メールアドレス" required>
                </div>
                <div class="col-4">
                    <input type="password" class="form-control" v-model="user.password" placeholder="パスワード">
                </div>
                <div class="col-4">
                    <div v-if="user.role === Role.OWNER" class="form-control">{{ Role.get(user.role) }}</div>
                    <form-select
                        v-else
                        v-model="user.role"
                        :options="Role.options()"
                        :required="true"
                    />
                </div>
                <div class="col-2">
                    <button class="btn btn-primary" @click="updateUser(user)">更新</button>
                </div>
                <div class="col-2">
                    <template v-if="user.role !== Role.OWNER">
                    <button class="btn btn-outline-danger" @click="removeUserReady(user)">削除</button>
                    </template>
                </div>
            </div>
        </template>

        <div class="row mt-5">
            <div class="col-4">
                <input type="text" class="form-control" v-model="new_user.user_name" placeholder="名前" required>
            </div>
            <div class="col-8">
                <input type="email" class="form-control" v-model="new_user.email" placeholder="メールアドレス" required>
            </div>
            <div class="col-4">
                <input type="password" class="form-control" v-model="new_user.password" placeholder="パスワード" required>
            </div>
            <div class="col-4">
                <form-select
                    v-model="new_user.role"
                    empty_option="-- 権限を選択 --"
                    :options="Role.options()"
                    :required="true"
                />
            </div>
            <div class="col-4">
                <button class="btn btn-primary" @click="createUser()">新規登録</button>
            </div>
        </div>
    </section>

    <confirm-dialog ref="confirm_remove" @ok="removeUser()">
        <p>削除してもよろしいですか？</p>
    </confirm-dialog>
</template>

<script>
import InlineLoader from '@/shared/components/tool/InlineLoader';
import ConfirmDialog from '@/shared/components/tool/ConfirmDialog.vue';
import FormSelect from '@/shared/components/form/FormSelect';
import User from '@/shared/models/entities/user'
import Role from '@/shared/models/enums/role'

export default {
    name: 'PageManageUser',
    components: {
        InlineLoader,
        ConfirmDialog,
        FormSelect,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: false,
            users: [],
            new_user: new User(),
            removing_user: null,
            Role,
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loading = true;
            this.$http.get('/users')
            .then((response) => {
                this.users = response.data.map((row) => new User(row));
            })
            .finally(() => {
                this.loading = false;
            });
        },
        createUser() {
            this.startScreenLoading();
            this.$http.post('/users', this.new_user)
            .then((response) => {
                this.users.push(new User(response.data));
                this.showMessage('登録しました');
                this.new_user = new User();
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        updateUser(user) {
            this.startScreenLoading();
            this.$http.put('/users/' + user.user_id, user)
            .then(() => {
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
        removeUserReady(user) {
            this.removing_user = user;
            this.$refs.confirm_remove.show();
        },
        removeUser() {
            this.startScreenLoading();
            this.$http.delete('/users/' + this.removing_user.user_id)
            .then(() => {
                this.showMessage('削除しました');
                this.users = this.users.filter((user) => user.user_id !== this.removing_user.user_id);
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
